/* Ensure the root element takes up the full width of the viewport */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Ensure the main container does not exceed the viewport width */
.container {
  max-width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Add responsive styles for other elements as needed */
.table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

td, th {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping */
}

/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f7f6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global styling */
input,
textarea,
button {
  font-size: 14px; /* Default for larger screens */
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}

/* Mobile-specific styling */
@media (max-width: 600px) {
  input,
  textarea,
  button {
    font-size: 16px; /* Mobile-specific size */
  }
}

@media (max-width: 600px) {
  input,
  textarea,
  button {
    width: 100%; /* Full-width inputs on mobile */
  }
}

.error {
  color: #D00;
}

/* Container for the form and list */
.container {
  max-width: 900px; /* Adjusted max-width for more space */
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
/* Container for the form and list */
.hundredPercent {
  width: 100%;
}

h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Title styles */
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

table#jobs {
  width: 100%;
  margin-top: 20px;
  table-layout: auto;
}
.description-column {
  width: 100%;
  padding: 0 5px;
}
td {
  white-space: nowrap;
  /* padding: 0 15px; */
}
#jobs .time {
  font-family: 'monospace'
}
#jobs td {
  text-align: center;
}
#jobs td.left {
  text-align: left;
}
#jobs td.right {
  text-align: right;
}
#jobs td.paid {
  color: #060;
}
#jobs td.unpaid {
  color: #B00;
  font-weight: bold;
}
#jobs td.left {
  text-align: left;
}
/* Form Styles */
form {
  display: flex;
  justify-content: space-between; /* Ensure fields spread evenly */
  align-items: center;
  gap: 15px; /* Space between fields */
}

form label {
  margin-bottom: 5px;
  font-weight: normal;
}

form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

form input[type="text"],
form input[type="number"] {
  flex: 1; /* Make inputs take equal space */
}

form input[type="text"] {
  max-width: 40%; /* Description takes more space */
}

form input[type="number"] {
  max-width: 15%; /* Hours and minutes inputs are smaller */
}

form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #0056b3;
}

/* Job List Styles */
ul {
  list-style: none;
  padding: 0;
}

ul li {
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Responsive Design */
@media (max-width: 900px) {
  .container {
    padding: 15px;
  }

  form {
    flex-wrap: wrap; /* On smaller screens, wrap the form fields */
  }

  form input[type="text"] {
    max-width: 100%;
  }

  form input[type="number"] {
    max-width: 45%;
  }

  ul li {
    flex-direction: column;
    align-items: flex-start;
  }
}


/* Default form field layout */
.form-field {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.form-field label {
  flex: 1;
  margin-right: 1rem;
}

.form-field input {
  flex: 2;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .form-field {
    display: flex;
    flex-direction: row; /* Ensure label and input are in a row */
    align-items: center;
    justify-content: space-between; /* Space between label and input */
    width: 100%;
  }

  .form-field label {
    flex: 1;
    margin-right: 1rem;
    text-align: left; /* Align label text to the left on mobile */
  }

  .form-field input {
    flex: 2;
    width: 100%; /* Ensure input takes up the remaining space */
  }
}